/**
 * These are scripts that have been in the project for a long time.
 * We donÄt quite know what these do, but it seems like they a required.
 */

document.addEventListener("DOMContentLoaded", function () {
  $(".skip-button").click(function (e) {
    e.preventDefault();
    var courseUrl = $(".next-lecture").next().attr("data-lecture-url");
    var completeUrl = "http://" + window.location.hostname + courseUrl;
    window.location.href = completeUrl;
  });

  if (document.querySelector(".js-filter")) {
    var sections = document.querySelectorAll(".course-section");
    var filter = Filter(sections);

    var filterWrapper = document.querySelector(".js-filter");
    var status = filterWrapper.querySelector(".js-filter-selection");
    var query = filterWrapper.querySelector(".js-filter-query");

    query.addEventListener("keyup", runFilter);
    status.addEventListener("change", runFilter);

    // eslint-disable-next-line
    function runFilter() {
      var currentStatus = parseInt(status.value, 10);
      var currentQuery = query.value;

      filter({ status: currentStatus, query: currentQuery });
    }

    query.value = "";
    status.value = "2";
  }
});

function Filter(sections_) {
  var sections = [];
  var STATUS = {
    Open: 0,
    Completed: 1,
    All: 2,
  };

  var State = {
    ready: false,
    queue: [],
  };

  getElements(Array.prototype.slice.call(sections_));

  return filter;

  function filter(predicate) {
    if (State.ready === false) {
      State.queue.push(predicate);
      return;
    }

    var status = predicate.status;
    var query = predicate.query;

    sections.forEach(function (section) {
      var disable = section.children.filter(match);
      var parent = section.section.parentElement;

      if (disable.length === 0) {
        parent.style.display = "none";
      } else {
        parent.style.display = "";
        parent.classList.remove("dropdown");
      }
    });

    function match(item) {
      var statusMatch = status === STATUS.All ? true : status === item.status;

      var titleMatch = query === "" ? true : fuzzysearch(query, item.title);

      var display = statusMatch && titleMatch;

      if (display === false) {
        item.elm.style.display = "none";
      } else {
        item.elm.style.display = "";
      }

      return display;
    }
  }

  function getElements(arr) {
    var head = arr[0];
    var tail = arr.slice(1);

    if (head === undefined) {
      State.ready = true;
      State.queue.forEach(function (pred) {
        filter(pred);
      });
      State.queue = [];
      return;
    }

    var section = head.querySelector(".section-list");
    var item = { section: section, children: [] };

    var end = section.children.length;

    for (var i = 0; i < end; i = i + 1) {
      var elm = section.children[i];
      item.children.push({
        status: getStatus(elm),
        title: getTitle(elm),
        elm: elm,
      });
    }

    sections.push(item);

    requestIdleCallback(() => getElements(tail));
  }

  function getStatus(elm) {
    return elm.classList.contains("completed") ? STATUS.Completed : STATUS.Open;
  }

  function getTitle(elm) {
    var item = elm.querySelector(".lecture-name");
    return item.textContent.trim();
  }

  // https://github.com/bevacqua/fuzzysearch
  function fuzzysearch(needle, haystack) {
    var hlen = haystack.length;
    var nlen = needle.length;
    if (nlen > hlen) {
      return false;
    }
    if (nlen === hlen) {
      return needle === haystack;
    }
    // eslint-disable-next-line
    outer: for (var i = 0, j = 0; i < nlen; i++) {
      var nch = needle.charCodeAt(i);
      while (j < hlen) {
        if (haystack.charCodeAt(j++) === nch) {
          // eslint-disable-next-line
          continue outer;
        }
      }
      return false;
    }
    return true;
  }
}

// https://developers.google.com/web/updates/2015/08/using-requestidlecallback
window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    var start = Date.now();
    return setTimeout(function () {
      // eslint-disable-next-line
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id);
  };
