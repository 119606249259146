document.addEventListener("DOMContentLoaded", function() {
    instantiateDateSelectors('birthdate');
    instantiateDateSelectors('arrivaldate');

    // Custom date picker
    function instantiateDateSelectors(selectNamePrefix) {
        // setup data
        var now = new Date();
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'];
        var currentYear = now.getFullYear();

        // get select fields
        var yearSelect = document.querySelector("select[name='"+selectNamePrefix+"-year']");
        var monthSelect = document.querySelector("select[name='"+selectNamePrefix+"-month']");
        var daySelect = document.querySelector("select[name='"+selectNamePrefix+"-day']");

        if (daySelect) {
            updateDaySelect(daySelect, now.getMonth());
            //daySelect.selectedIndex = now.getDate() - 1;
            daySelect.selectedIndex = 0;
        }

        if (yearSelect) {
            populateSelect(yearSelect, currentYear - 5, currentYear + 5, null);
            yearSelect.selectedIndex = 5;
            //yearSelect.selectedIndex = 1;
        }

        if (monthSelect) {
            populateSelect(monthSelect, 1, null, months);
            monthSelect.selectedIndex = now.getMonth();
            monthSelect.selectedIndex = 0;
            // update day select field on change with current number of months
            monthSelect.addEventListener('change', function(e) {
              var prefix = e.target.name.split('-')[0];
              var daySelect = document.querySelector("select[name='"+prefix+"-day']");
              if (daySelect) {
                updateDaySelect(daySelect, e.target.selectedIndex);
              } else {
                console.log('Could not find day select element');
              }
            }, false)
        }
    }

    function updateDaySelect(select, month) {
        // save selected date
        var selected = select.selectedIndex;
        select.options.length = 0;
        var year = new Date().getFullYear();
        var firstDay = new Date(year, month, 1).getDate();
        var lastDay = new Date(year, month + 1, 0).getDate();
        populateSelect(select, firstDay, lastDay, null);
        select.selectedIndex = (selected > lastDay - 1 ) ? 0 : selected;
    }

    function populateSelect(selector, startVal, endVal, ary) {
        var valueAry = ary || [];
        var length = endVal + 1;
        var start = startVal;
        if (ary) {
            length = ary.length;
            start = 0;
        }

        for (var i = start; i < length; i += 1) {
            var opt = document.createElement("option");
            if(ary) {
              opt.value = i+1;
            } else {
              opt.value = i;
            }
            opt.innerHTML = valueAry[i] || i;

            selector.appendChild(opt);
        }
    }
});
