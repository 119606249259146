$(document).ready(function () {
  $(".course-section").toggleClass("dropdown");
  setTimeout(function () {
    $(".next-lecture").parent().parent().removeClass("dropdown");
  }, 500);

  $(".section-title").click(function () {
    $(this).parent().toggleClass("dropdown");
  });

  setTimeout(function () {
    var itemslist = $(".next-lecture").parent().parent().parent().prevAll();

    itemslist.each(function () {
      $(this).find(".section-title").addClass("completed");
    });
  }, 1000);
});
