document.addEventListener("DOMContentLoaded", function () {
  var overlay = document.getElementById("overlay");
  var backgroundOverlay = document.getElementById("background-overlay");
  //var baseUrl = 'http://staging.hatchery.io/kosmos-backend';
  //var baseUrl = 'https://www.welpenschule-online.de/backendpost/public';

  var baseUrl = "https://backend.kosmos-campus.de/post/public";
  //var baseUrl = 'https://hatchery.io/kosmos-backend';
  //var baseUrl = 'http://localhost:8080';

  if (overlay) {
    overlay.className += " deactivate";
  }
  if (backgroundOverlay) {
    backgroundOverlay.className += " deactivate";
  }

  if (overlay) {
    var email = document.getElementById("email-input").value;
    var userid = parseInt(document.getElementById("userid-form").value);
  }

  var json;

  function checkUserEmail() {
    if (overlay) {
      if (location.protocol != "https:") {
        // check if http
        location.href =
          "https:" +
          window.location.href.substring(window.location.protocol.length);
      } else {
        // check if user email is already in database
        var urlEmail = baseUrl + "/users/validate?email=" + email;
        var emailXhr = new XMLHttpRequest();
        emailXhr.open(
          "GET",
          urlEmail,
          true,
          "kosmos_benutzer",
          "4zf09n320m93xn9224cn9"
        );
        emailXhr.withCredentials = true;
        emailXhr.setRequestHeader("Content-type", "application/json");
        emailXhr.onreadystatechange = function () {
          if (emailXhr.readyState === 4) {
            if (emailXhr.status === 404) {
              console.log("email not found, start dog request.");
              // user not registered, activate overlay
              overlay.classList.remove("deactivate");
              backgroundOverlay.classList.remove("deactivate");
            } else if (emailXhr.status === 400) {
              json = JSON.parse(emailXhr.responseText);
              console.log("Request failed:", json.error);
            } else if (emailXhr.status === 500) {
              console.log(emailXhr);
              json = JSON.parse(emailXhr.responseText);
              console.log("Request failed:", json.error);
            } else {
              // user registered
              console.log(emailXhr.responseText);
              console.log("Dog Date registered.");
              json = JSON.parse(emailXhr.responseText);
              createInfoBox(json);
              prefillForm(json);
            }
          }
        };
        emailXhr.send();
      }
    }
  }

  function checkUserId() {
    if (overlay) {
      if (location.protocol != "https:") {
        // check if https
        location.href =
          "https:" +
          window.location.href.substring(window.location.protocol.length);
      } else {
        // check if user email is already in database
        var urlUserid = baseUrl + "/users/uservalidate?userid=" + userid;
        var useridXhr = new XMLHttpRequest();
        useridXhr.open("GET", urlUserid, true);
        useridXhr.onreadystatechange = function () {
          if (useridXhr.readyState === 4) {
            if (useridXhr.status === 404) {
              console.log("userid not found, start dog request.");
              checkUserEmail();
              // user not registered, activate overlay

              overlay.classList.remove("deactivate");
              backgroundOverlay.classList.remove("deactivate");
            } else if (useridXhr.status === 400) {
              json = JSON.parse(useridXhr.responseText);
              console.log("Request failed:", json.error);
            } else if (useridXhr.status === 500) {
              console.log(useridXhr);
              json = JSON.parse(useridXhr.responseText);
              console.log("Request failed:", json.error);
            } else {
              // user registered
              console.log("Dog Date registered.");
              json = JSON.parse(useridXhr.responseText);
              createInfoBox(json);
              prefillForm(json);
            }
          }
        };
        useridXhr.send();
      }
    }
  }

  function prefillForm(json) {
    var dogInput = document.getElementById("input-dogname");
    dogInput.value = json.data.dogname;

    if (json.data.birthdate != null) {
      var birthdateDay = document.getElementsByName("birthdate-day")[0];
      birthdateDay.value = new Date(json.data.birthdate).getDate();

      var birthdateMonth = document.getElementsByName("birthdate-month")[0];
      birthdateMonth.value = new Date(json.data.birthdate).getMonth() + 1;

      var birthdateYear = document.getElementsByName("birthdate-year")[0];
      birthdateYear.value = new Date(json.data.birthdate).getFullYear();
    }

    if (json.data.arrivaldate != null) {
      var arrivalDay = document.getElementsByName("arrivaldate-day")[0];
      arrivalDay.value = new Date(json.data.arrivaldate).getDate();

      var arrivalMonth = document.getElementsByName("arrivaldate-month")[0];
      arrivalMonth.value = new Date(json.data.arrivaldate).getMonth() + 1;

      var arrivalYear = document.getElementsByName("arrivaldate-year")[0];
      arrivalYear.value = new Date(json.data.arrivaldate).getFullYear();
    }
  }

  //checkUserEmail();
  //checkUserId();

  // first click function
  var nextButton = document.getElementById("next-button");
  if (nextButton) {
    nextButton.onclick = function (e) {
      e.preventDefault();
      var firstStep = document.getElementById("first-step");
      var secondStep = document.getElementById("second-step");

      firstStep.className += " deactivate";
      secondStep.className += " activate";
    };
  }

  // send request & close overlay
  var formData = {};
  var form = document.getElementById("user-form");
  if (form) {
    form.addEventListener("submit", function (e) {
      e.preventDefault();

      var formElements = e.target.querySelectorAll("input, select");
      for (var i = 0; i < formElements.length; i++) {
        var el = formElements[i];
        if (el.type === "submit") continue;

        formData[el.name] = el.value;
      }
      console.log(formData);

      var xhr = new XMLHttpRequest();
      var postUrl = "https://backend.kosmos-campus.de/post/public";
      xhr.open("POST", postUrl + "/users", true);
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.onreadystatechange = function () {
        overlay.className += " deactivate";
        backgroundOverlay.className += " deactivate";
        //setCookie("kosmos", "registered", 365);

        if (xhr.readyState === 4) {
          var json = JSON.parse(xhr.responseText);
          console.log(json);
          //checkUserEmail();
          checkUserId();

          if (xhr.status === 200) {
            // request successful!;
            console.log(json.result);
          } else if (xhr.status >= 400) {
            // request failed!;
            console.log(json.error);
          }
        }
      };
      xhr.send(JSON.stringify(formData));
    });
  }

  function createInfoBox(json) {
    var oneDay = 24 * 60 * 60 * 1000;
    var now = new Date();
    var arrivalDate = new Date(json.data.arrivaldate);

    if (
      !document.getElementById("no-doggo-date").classList.contains("deactivate")
    ) {
      document.getElementById("no-doggo-date").classList += " deactivate";
    }

    if (
      !document
        .getElementById("doggo-countdown")
        .classList.contains("deactivate")
    ) {
      document.getElementById("doggo-countdown").classList += " deactivate";
    }

    if (
      !document.getElementById("doggo-days").classList.contains("deactivate")
    ) {
      document.getElementById("doggo-days").classList += " deactivate";
    }


    if (json.data.arrivaldate === null) {
      console.log("No Doggo");
      const boxElement = document.getElementById("no-doggo-date");
      boxElement.classList.remove("deactivate");
    } else if (arrivalDate > now) {
      console.log("Doggo countdown");
      const dogname = document.getElementById("dogname");
      if (json.data.dogname != null) {
        const dognameString = json.data.dogname + " ";
        dogname.innerHTML = dognameString;
      }

      const boxElement = document.getElementById("doggo-countdown");
      const countdownDay = document.getElementById("countdown-days");

      // Arrival Date is in the future
      var diffDays = Math.ceil(
        Math.abs((arrivalDate.getTime() - now.getTime()) / oneDay)
      );
      countdownDay.innerHTML = diffDays;

      boxElement.classList.remove("deactivate");
    } else if (arrivalDate < now) {
      console.log("Doggo is here!");
      const dogname = document.getElementById("dogname2");
      if (json.data.dogname != null) {
        const dognameString = json.data.dogname + " ";
        dogname.innerHTML = dognameString;
      }

      const boxElement = document.getElementById("doggo-days");
      const countdownDay = document.getElementById("reverse-countdown-days");

      // Arrival Date is in the future
      const diffDays = Math.ceil(
        Math.abs((arrivalDate.getTime() - now.getTime()) / oneDay)
      );
      countdownDay.innerHTML = diffDays;

      boxElement.classList.remove("deactivate");
    } else {
      console.log("Where is Doggo");
    }
  }

  function openPopup(e) {
    e.preventDefault();
    overlay.classList.remove("deactivate");
    backgroundOverlay.classList.remove("deactivate");
  }

  function setupPopupListener() {
    var classname = document.getElementsByClassName("edit-popup");

    for (var i = 0; i < classname.length; i++) {
      classname[i].addEventListener("click", openPopup, false);
    }
  }

  setupPopupListener();

  // Option for checkboxes
  var checkboxBirthdate = document.querySelector(
    "input[name=birthdate-unknown]"
  );
  if (checkboxBirthdate) {
    checkboxBirthdate.addEventListener("change", function () {
      if (this.checked) {
        // Checkbox is checked..
        document.querySelector("select[name=birthdate-day]").value = "00";
        document.querySelector("select[name=birthdate-day]").disabled = true;
        document.querySelector("select[name=birthdate-month]").value = "00";
        document.querySelector("select[name=birthdate-month]").disabled = true;
        document.querySelector("select[name=birthdate-year]").value = "0000";
        document.querySelector("select[name=birthdate-year]").disabled = true;
        document
          .querySelector(".birthday-container")
          .classList.add("deactivate");
      } else {
        // Checkbox is not checked..
        document.querySelector("select[name=birthdate-day]").value = "1";
        document.querySelector("select[name=birthdate-day]").disabled = false;
        document.querySelector("select[name=birthdate-month]").value = "1";
        document.querySelector("select[name=birthdate-month]").disabled = false;
        document.querySelector("select[name=birthdate-year]").value = "2017";
        document.querySelector("select[name=birthdate-year]").disabled = false;
        document
          .querySelector(".birthday-container")
          .classList.remove("deactivate");
      }
    });
  }

  var checkboxArrival = document.querySelector(
    "input[name=arrivaldate-unknown]"
  );

  if (checkboxArrival) {
    checkboxArrival.addEventListener("change", function () {
      if (this.checked) {
        // Checkbox is checked..
        document.querySelector("select[name=arrivaldate-day]").value = "00";
        document.querySelector("select[name=arrivaldate-day]").disabled = true;
        document.querySelector("select[name=arrivaldate-month]").value = "00";
        document.querySelector(
          "select[name=arrivaldate-month]"
        ).disabled = true;
        document.querySelector("select[name=arrivaldate-year]").value = "0000";
        document.querySelector("select[name=arrivaldate-year]").disabled = true;
        document
          .querySelector(".arrivaldate-container")
          .classList.add("deactivate");
      } else {
        // Checkbox is not checked..
        document.querySelector("select[name=arrivaldate-day]").value = "1";
        document.querySelector("select[name=arrivaldate-day]").disabled = false;
        document.querySelector("select[name=arrivaldate-month]").value = "1";
        document.querySelector(
          "select[name=arrivaldate-month]"
        ).disabled = false;
        document.querySelector("select[name=arrivaldate-year]").value = "2017";
        document.querySelector(
          "select[name=arrivaldate-year]"
        ).disabled = false;
        document
          .querySelector(".arrivaldate-container")
          .classList.remove("deactivate");
      }
    });
  }

  // close popup
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function closePopup() {
    overlay.className += " deactivate";
    backgroundOverlay.className += " deactivate";
  }

  /* click on dark layer close
  var overlayLayer = document.getElementById('background-overlay');
  overlayLayer.addEventListener('click', closePopup, false);
  */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  (function (history) {
    var pushState = history.pushState;
    history.pushState = function (state) {
      if (typeof history.onpushstate == "function") {
        history.onpushstate({ state: state });
      }

      //Dirty fix
      if (
        state.url === "https://kurse.kosmos-campus.de/courses/enrolled/210029"
      ) {
        window.location.href =
          "https://kurse.kosmos-campus.de/courses/enrolled/210029";
      }

      return pushState.apply(history, arguments);
    };
  })(window.history);
});
