function setupSliders() {
  /**
   * sliders
   */
  const sliders = document.querySelectorAll(".content-slider");

  // for each slider
  sliders.forEach((slider) => {
    let index = 1;
    // get slides for slider
    const slides = slider.querySelectorAll(".slides");

    // assign event listener to buttons
    const buttonNext = slider.querySelector(".display-right-slider");
    const buttonPrevious = slider.querySelector(".display-left-slider");
    buttonNext.addEventListener("click", () => switchSlide(+1));
    buttonPrevious.addEventListener("click", () => switchSlide(-1));

    // handle switch slide
    function switchSlide(n) {
      handleActiveSlide((index += n));
    }

    // handle active slide
    function handleActiveSlide(n) {
      if (n > slides.length) {
        index = 1;
      }
      if (n < 1) {
        index = slides.length;
      }
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[index - 1].style.display = "block";
    }

    // Set pagination number but only if its empty
    slides.forEach((slide, i) => {
      const pagination = slide.querySelector(".slider-number, .slider-overlay-number");
      if (pagination && pagination.innerHTML === "") {
        pagination.innerHTML = `${i + 1}/${slides.length}`;
      }
    });

    switchSlide(0);
  });
}

/**
 * We have to setup the slides when the DOMContentLoaded but also on lecture through the sidebar.
 * We can only detect those lecture changes by observing the body and checking if the lecture content changed.
 * This solutions is far from perfect, but it works.
 */

window.addEventListener("load", () => {
  // zu überwachende Zielnode (target) auswählen
  const targetEl = document.querySelector("body");

  // eine Instanz des Observers erzeugen
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach((mutation) => {
      let lectureContentChanged = Array.from(mutation.addedNodes).find(
        (node) => node.classList && node.classList.contains("lecture-content")
      );
      if (lectureContentChanged) {
        setupSliders();
      }
    });
  });

  const config = { childList: true };

  observer.observe(targetEl, config);
});

window.addEventListener("DOMContentLoaded", () => {
  setupSliders();
});
